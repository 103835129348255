<template>
  <div>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <v-select
            v-model="partnerSelected"
            :options="partnerDD"
            :clearable="false"
            label="text"
            placeholder="Partner auswählen"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          {{ partnerSelected }}
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
} from 'bootstrap-vue'

import vSelect from 'vue-select'

import {
  onUnmounted,
  ref,
} from '@vue/composition-api'

import store from '@/store'
import bonusStoreModule from '../bonus/bonusStoreModule'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    vSelect,
  },
  setup() {
    const BONUS_STORE_MODULE = 'bonus-store-module'
    //  Register BonusStoreModule
    if (!store.hasModule(BONUS_STORE_MODULE)) store.registerModule(BONUS_STORE_MODULE, bonusStoreModule)

    //  Unregister BonusStoreModule
    onUnmounted(() => {
      if (store.hasModule(BONUS_STORE_MODULE)) store.unregisterModule(BONUS_STORE_MODULE)
    })

    const partnerSelected = ref('')
    const partnerDD = ref([])

    store.dispatch(`${BONUS_STORE_MODULE}/fetchPartnerDD`)
      .then(response => {
        response.data.forEach(element => {
          const option = {
            text: element.title,
            value: element.value,
          }
          partnerDD.value.push(option)
        })
      })

    return {
      partnerDD,
      partnerSelected,
    }
  },
}
//
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
